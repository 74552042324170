import { TOKEN_KEY } from '@/constants';
import cookie from '@/utils/cookie';

export const useUser = () => {
  const isAuthenticated = cookie.get(TOKEN_KEY) !== null;

  return {
    isAuthenticated,
  };
};
