import { NavigationGuard } from 'vue-router';
import { TOKEN_KEY } from '@/constants';
import cookie from '@/utils/cookie';

export const memberAuth: NavigationGuard = async (to, from, next) => {
  const isAuthenticated = cookie.get(TOKEN_KEY) !== null;

  if (to.matched.some(record => record.meta.disableIfLoggedIn)) {
    if (isAuthenticated) {
      next('/maker-management');
    } else {
      next();
    }
  } else {
    if (isAuthenticated) {
      next();
    } else {
      next('/login');
    }
  }
};
