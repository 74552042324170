export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';

export const makerFields = [
  {
    key: 'name',
    label: 'メーカー名',
    tdClass: 'text-truncate',
  },
  {
    key: 'personInCharge',
    label: '担当者名',
    tdClass: 'text-truncate',
  },
  {
    key: 'address',
    label: '住所',
    tdClass: 'text-truncate',
  },
  {
    key: 'email',
    label: 'メールアドレス',
    tdClass: 'text-truncate',
  },
  {
    key: 'phoneNumber',
    label: '電話番号',
    tdClass: 'text-truncate',
  },
  {
    key: 'type',
    label: 'タイプ',
  },
  {
    key: 'status',
    label: '',
  },
  {
    key: 'detail',
    label: '',
  },
  {
    key: 'edit',
    label: '',
  },
  {
    key: 'delete',
    label: '',
  },
];

export const makerTypeOption = [
  { value: '1', text: '配送' },
  { value: '2', text: '持ち帰り' },
];

export const giftFields = [
  {
    key: 'giftCode',
    label: '景品ID',
    tdClass: 'text-word-wrap',
  },
  {
    key: 'sortingNumber',
    label: '表示順',
    tdClass: 'text-word-wrap',
  },
  {
    key: 'name',
    label: '景品名',
    tdClass: 'text-truncate',
  },
  {
    key: 'point',
    label: 'ポイント',
  },
  {
    key: 'description',
    label: '景品コメント',
  },
  {
    key: 'makerName',
    label: 'メーカー',
    tdClass: 'text-truncate',
  },
  {
    key: 'status',
    label: '',
  },
  {
    key: 'edit',
    label: '',
  },
  {
    key: 'delete',
    label: '',
  },
];

export const orderFields = [
  {
    key: 'id',
    label: '発注番号',
  },
  {
    key: 'clientName',
    label: 'お客様名',
    tdClass: 'text-truncate',
  },
  {
    key: 'orderDate',
    label: '発注作成日',
  },
  {
    key: 'personInCharge',
    label: '発注者',
    tdClass: 'text-truncate',
  },
  {
    key: 'hatBranchName',
    label: '発注支店',
    tdClass: 'text-truncate',
  },
  {
    key: 'detail',
    label: '',
  },
  {
    key: 'delete',
    label: '',
  },
];
